<template>
    <div>
        <el-dialog :visible.sync="swiperShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>编辑店铺基础资料</span>
            </div>
            <div v-if="current ==0">
                <div class="publicity">
                    <el-upload :limit="5" :action="action" :on-success="beforeLoadL" list-type="picture-card" :on-remove="removeImgL">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="upload">确定上传</el-button>
                </div>
            </div>
            <div v-else>
                <div class="no-win">
                    <img src="../../assets/images/serve/qr.png" alt="">
                    上传成功！
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        swiperShow: Boolean
    },
    data() {
        return {
            current: 0,
            form1: {
                images: ''
            },
            action: this.$util.upload,
        };
    },

    mounted() {

    },

    methods: {
        // 轮播上传
        beforeLoadL(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form1.images = imgs.join(',')
        },
        removeImgL(file, fileList) {
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form1.images = imgs.join(',')
            console.log(this.form1);

        },
        handleClose() {
            this.$emit('close');

        },
        // 上传
        upload() {
            this.$util.postJ('/shop/updateShopInfo', this.form1,{type:true}).then(res => {
                if (res.code == 200) {
                    this.current++
                } else {
                    this.$message.error(res.message)
                }

            })
        }
    },
};
</script>

<style lang="scss" scoped>
.state {
    padding: 0px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
.publicity {
    padding: 57px 0 0;
    display: flex;
    align-items: center;
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
    }
}
/deep/.el-upload--picture-card {
    width: 198px;
    height: 124px;
    line-height: 130px;
    margin-right: 90px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 198px;
    height: 124px;
    margin-right: 90px;
    margin-bottom: 50px;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
</style>