<template>
	<div>
		<el-dialog :visible.sync="editInfoShow" width="1200px" :close-on-click-modal="false"
			:close-on-press-escape="false" :before-close="handleClose">
			<div class="state">
				<img src="../../assets/images/serve/gth.png" alt="">
				正在 <span>编辑店铺资料</span>
			</div>
			<el-form style="margin-top:40px" label-position="left" label-width="80px" @submit.native.prevent>
				<el-form-item label="标题1：">
					<el-input placeholder="不超过10个字符" v-model="title1"></el-input>
				</el-form-item>
				<vue-editor v-model="content1" id="editor" useCustomImageHandler @image-added="handleImageAdded" />
				<el-form-item style="margin-top:40px" label="标题2：">
					<el-input placeholder="不超过10个字符" v-model="title2"></el-input>
				</el-form-item>
				<vue-editor v-model="content2" id="editor2" useCustomImageHandler @image-added="handleImageAdded2" />
				<!-- 底部按钮 -->
				<div class="foot-btn">

					<el-button class="f-btn" @click="revise">确定上传</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import axios from "axios";
	import {
		VueEditor
	} from "vue2-editor";
	export default {
		components: {
			VueEditor
		},
		props: {
			editInfoShow: Boolean,
			details: Array
		},
		data() {
			return {
				contentList: [ //富文本详情
					{
						title: '',
						content: ''
					},
					{
						title: '',
						content: ''
					},
				],
				title1: '',
				title2: '',
				content1: '',
				content2: ''
			};
		},

		mounted() {
			this.details.forEach((el, index) => {
				this.contentList.push(el)
				this.contentList.splice(0, 2)
				console.log(el)
			})
			this.content1 = this.details[0].content
			this.content2 = this.details[1].content
			this.title1 = this.details[0].title
			this.title2 = this.details[1].title
		},

		methods: {
			// 富文本上传图片
			handleImageAdded(file, Editor, cursorLocation, resetUploader) {
				// An example of using FormData
				// NOTE: Your key could be different such as:
				// formData.append('file', file)

				var formData = new FormData();

				formData.append('file', file);
				console.log(new FormData());
				axios({
						url: this.$util.host + "/file/upload",
						method: "POST",
						data: formData
					})
					.then(result => {
						let url = this.$util.host + result.data.data.url; // Get url from response
						console.log(url);
						Editor.insertEmbed(cursorLocation, "image", url);
						resetUploader();
					})
					.catch(err => {
						console.log(err);
					});


			},
			// 富文本上传图片
			handleImageAdded2(file, Editor, cursorLocation, resetUploader) {
				// An example of using FormData
				// NOTE: Your key could be different such as:
				// formData.append('file', file)

				var formData = new FormData();

				formData.append('file', file);
				console.log(new FormData());
				axios({
						url: this.$util.host + "/file/upload",
						method: "POST",
						data: formData
					})
					.then(result => {
						let url = this.$util.host + result.data.data.url; // Get url from response
						console.log(url);

						Editor.insertEmbed(cursorLocation, "image", url);
						resetUploader();
					})
					.catch(err => {
						console.log(err);
					});


			},
			revise() {
				// this.contentList[0].title = this.title1
				// this.contentList[1].title = this.title2
				// this.contentList[0].content = this.content1
				// this.contentList[1].content = this.content2
				let list = [{
						title: this.title1,
						content: this.content1
					},
					{
						title: this.title2,
						content: this.content2
					}
				]
				console.log(list)
				this.contentList = list
				this.$util.postJ('/shop/setDet', this.contentList, {
					type: true
				}).then(res => {
					if (res.code == 200) {
						this.handleClose()
					} else {
						this.$message.error(res.message)
					}
				})
			},
			handleClose() {

				this.$emit('close');

			},
		},
	};
</script>

<style lang="scss" scoped>
	.state {
		padding: 0px 0 10px;
		border-bottom: 1px solid #eeeeee;
		display: flex;
		align-items: center;
		font-size: 22px;
		color: #333333;

		span {
			color: #e40012;
		}

		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}

	.form {
		display: flex;
		align-items: center;
		margin-top: 30px;
		margin-left: 200px;

		.lable {
			min-width: 100px;
			text-align: right;
			font-size: 16px;
			color: #999999;
			margin-right: 20px;
		}

		span {
			color: #222;
			font-size: 16px;
		}

		img {
			width: 100px;
			height: 100px;
			border-radius: 100%;
		}
	}

	.foot-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		font-size: 16px;
		cursor: pointer;
		color: #e40012;

		.f-btn {
			padding: 15px 180px;
			background: #e40012;
			color: #fff;
			font-size: 18px;
			margin-left: 40px;
		}
	}
</style>
