<template>
    <div>
        <el-dialog :visible.sync="providerShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="provider">成为专业的服务商，可以获得更多服务动态</div>
            <div v-if="current == 0">
                <div class="discount">
                    <div class="discount-item" :class="cur == index ? 'active' : ''" v-for="(item, index) in list" :key="item.id" @click="change(index, item)">
                        <div class="zhekou" v-if="index == 0">超折扣</div>
                        <div class="year">{{ item.year }}</div>
                        <div class="money">￥{{ item.money }}</div>
                        <div class="day textLine">{{ item.day }}</div>
                    </div>
                    <!-- <div class="discount-item">
                        <div class="year">2年</div>
                        <div class="money">￥600</div>
                        <div class="day">0.73元/天</div>
                    </div>
                    <div class="discount-item">
                        <div class="year">1年</div>
                        <div class="money">￥300</div>
                        <div class="day">0.73元/天</div>
                    </div> -->
                </div>
                <div class="hint">*支付方式</div>
                <div class="payment">
                    <el-radio v-model="form.payType" :label="0" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                我的钱包
                                <span>余额：{{ info.balance }}元</span>
                            </div>
                        </div>
                    </el-radio>
                    <div>平台</div>
                </div>
                <div class="payment">
                    <el-radio v-model="form.payType" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment">
                    <el-radio v-model="form.payType" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                微信
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="privilege">
                    <div></div>
                    <span>技术年费的特权</span>
                    <div></div>
                </div>
                <div class="table">
                    <div class="table-item">
                        <div>特权</div>
                        <div>企业店铺</div>
                        <div>个人店铺</div>
                    </div>
                    <div class="table-item" v-for="item in privilegeInfo" :key="item">
                        <div>{{ item }}</div>
                        <div><i class="el-icon-check"></i></div>
                        <div><i class="el-icon-close"></i></div>
                    </div>
                </div>
                <div class="foot-btn">
                    <el-button class="f-btn" @click="next">立即下单</el-button>
                </div>
            </div>
            <div v-if="current == 1">
                <div class="discount">
                    <div class="discount-item">
                        <div class="zhekou" v-if="cur == 0">超折扣</div>
                        <div class="year">{{ list[cur].year }}</div>
                        <div class="money">￥{{ list[cur].money }}</div>
                        <div class="day textLine">{{ list[cur].day }}</div>
                    </div>
                </div>
                <div class="hint">*支付方式</div>
                <div class="payment" v-if="form.payType == 0">
                    <el-radio v-model="form.payType" :label="0" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                我的钱包
                                <span>余额：{{ info.balance }}元</span>
                            </div>
                        </div>
                    </el-radio>
                    <div>平台</div>
                </div>
                <div class="payment" v-if="form.payType == 1">
                    <el-radio v-model="form.payType" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment" v-if="form.payType == 2">
                    <el-radio v-model="form.payType" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                微信
                            </div>
                        </div>
                    </el-radio>
                </div>

                <div class="hint" v-if="form.payType == 0">*支付密码</div>
                <div class="paypass" v-if="form.payType == 0">
                    <!-- password -->
                    <input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num" :id="'captcha' + index" @input="inputFinash(index)" @focus="adjust(index)" @keydown="inputDirection(index)" class="captcha_input_box row-center" maxlength="1" />
                </div>
                <div class="qr-code" ref="qrCodeUrl"></div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="pay" v-if="!qrCodeShow">付款</el-button>
                </div>
                <!-- <div class="hui">点击【付款后】款项在验收成果确认之前将由平台保管，待验收成功后将自动发往服务方。</div> -->
                <!-- <div>若选择对公转账，可直接转账至以下账户（转账时备注 购买服务）；</div>
                <div class="foot-content">
                    <div class="cell">
                        <div>公司名称:</div>
                    </div>
                    <div class="cell">
                        <div>银行账户:</div>
                    </div>
                    <div class="cell">
                        <div>开户行:</div>
                    </div>
                </div>
                <div style="color:#999">付款后将付款截图发给客服人员核实后可继续操作下一步</div> -->
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    props: {
        providerShow: Boolean,
    },
    data() {
        return {
            cur: 0, //选择年限的下标
            current: 0, //当前进度
            radio: 0, //支付方式
            list: [
                //支付列表
                { id: 0, year: "体验版", day: "原价3600元/年", money: "" },
                { id: 1, year: "商业版", day: "原价10800元/年", money: "" },
                { id: 2, year: "VIP版", day: "原价36800元/年", money: "" },
            ],
            form: {
                //支付技术支持费参数
                year: "",
                payType: 0,
                payPsd: "",
            },
            captchas: [
                // 密码
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },],
            info: {}, //个人基础信息
            level: "privilege_",
            privilegeInfo: "",
            code: "",
            id: "",
            state: 0,
            qrCodeShow: false
        };
    },

    mounted() {
        this.getInfo();
        this.getSys();
        this.getPrivilege();
    },

    methods: {
        // 获取支付状态
        payState() {
            this.$util
                .post("/center/myOrder/getRecordState", {
                    id: this.id,
                })
                .then((res) => {
                    console.log(res);
                    this.state = res.data.state;
                });
        },
        creatQrCode() {
            this.qrCodeShow = true;
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: this.code,
                width: 257,
                height: 257,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        //获取特权
        getPrivilege() {
            var num = 1
            num = this.cur + 1

            this.$util
                .post("/sys-param/list", {
                    paramKey: this.level + num,
                })
                .then((res) => {
                    console.log(res);

                    this.privilegeInfo = res.data[0].paramValue.split(",");
                });
        },
        //获取字典
        getSys() {
            this.$util
                .post("/sys-param/list", {
                    paramKey: "technology_3-technology_2-technology_1",
                })
                .then((res) => {
                    this.list[0].money = res.data[0].paramValue;
                    this.list[1].money = res.data[1].paramValue;
                    this.list[2].money = res.data[2].paramValue;
                });
        },
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
            });
        },
        next() {
            console.log(this.form.payType)
            // if (this.form.payType != '0' || this.form.payType != '1' || this.form.payType != '2') {
            //     return this.$message.error("请选择支付方式");
            // } else {
            //     this.current = 1;
            // }
            this.current = 1
        },
        pay() {
            this.form.year = this.cur + 1;

            if (this.form.payType == 0 && this.form.payPsd == "") {
                return this.$message.error("请输入支付密码");
            }

            this.form.payType = this.form.payType;
            this.$util.post("/shop/technologyPay", this.form).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    if (this.form.payType == 1 || this.form.payType == 2) {
                        this.code = res.data.code;
                        this.creatQrCode();
                        this.id = res.data.id;
                        let timmer = setInterval(() => {
                            if (this.state == "0") {
                                this.payState();
                            } else {
                                clearInterval(timmer);
                                if (this.state == 1) {
                                    this.handleClose();
                                    return this.$message.error("支付超时");
                                } else if (this.state == 2) {
                                    this.handleClose();
                                    location.reload()
                                    return this.$message.success("支付成功,请刷新");
                                }
                            }
                        }, 2000);
                    } else {
                        this.handleClose();
                    }
                    location.reload()
                } else {
                    this.$message.error(res.messgae);
                }
            });
        },
        handleClose() {
            this.state = 3;
            this.$emit("close");
        },
        // 输入密码
        adjust(index) {
            let dom = document.getElementById("captcha" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirection(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captcha" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captcha" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinash(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captcha" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.form.payPsd = code;
                }
            }
        },
        change(index, item) {
            this.cur = index;
            this.getPrivilege();
            this.form.year = item.year;
        },
    },
};
</script>

<style lang="scss" scoped>
.qr-code {
    width: 257px;
    height: 257px;
    // margin: 40px auto;
    margin-left: 30px;
    // background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.el-icon-check {
    color: #67c23a;
}
.el-icon-close {
    color: #e40012;
}
.textLine {
    text-decoration: line-through;
}
.provider {
    font-size: 30px;
    color: #222;
    text-align: center;
    font-weight: bold;
}
.discount {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    .discount-item {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 345px;
        height: 140px;
        padding: 30px 0;
        text-align: center;
        color: #fff;
        background: red;
        font-weight: bold;
        border-radius: 4px;
        box-shadow: 1px 1px 10px #ccc;
        .zhekou {
            background: #fff;
            transform: rotateZ(-45deg) translate(-20px, -10px);
            padding: 4px 0;
            width: 80px;
            text-align: center;
            font-size: 12px;
            color: #fa402e;
            position: absolute;
            top: 0;
            left: 0;
        }
        .year {
            font-size: 22px;
        }
        .money {
            margin: 18px 0 20px;
            font-size: 32px;
        }
        .day {
            font-size: 22px;
        }
    }
    .discount-item:nth-child(3) {
        background: linear-gradient(to right, #fe9949, #fa3d2d);
    }
    .discount-item:nth-child(2) {
        background: linear-gradient(to right, #f9bb40, #fb993a);
    }
    .discount-item:nth-child(1) {
        background: linear-gradient(to right, #39c1fc, #39c1fc);
    }
    .active {
        border: 2px solid #e40012;
    }
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.privilege {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #222;
    margin: 60px 0 50px;
    span {
        display: block;
        margin: 0 20px;
    }
    div {
        height: 2px;
        flex: 1;
    }
    div:nth-child(1) {
        background: url("../../assets/images/manage/zuo.png") round;
    }
    div:nth-child(3) {
        background: url("../../assets/images/manage/you.png") round;
    }
}
.table {
    border-top: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    .table-item {
        border-bottom: 1px solid #eeeeee;
        font-size: 18px;
        color: #222;
        text-align: center;
        display: flex;

        div {
            flex: 1;
            padding: 13px 0;
        }
        div:nth-child(2) {
            border-right: 1px solid #eeeeee;
            border-left: 1px solid #eeeeee;
        }
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0 30px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.foot-content {
    margin: 20px 0;
    .cell {
        color: #222222;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
    }
}
</style>
