<template>
	<div>
		<el-dialog :visible.sync="editInfoShow" width="1200px" :close-on-click-modal="false"
			:close-on-press-escape="false" :before-close="handleClose">
			<div class="state">
				<img src="../../assets/images/serve/gth.png" alt="">
				正在 <span>编辑我的资料</span>
			</div>
			<div class="avatar">
				*头像(支持jpg、png格式图片):
				<!-- <img v-if="form1.headPic!==''" style="width:100px; height:100px;border-radius:50% " :src="$util.host+form1.headPic" alt="" /> -->
				<div class="avatar-item">
					<el-upload :limit="1" :action="action" :file-list="fileList1" :on-success="beforeLoad"
						list-type="picture-card" :on-remove="removeImg">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<el-form style="margin-top:40px" label-position="left" label-width="100px" :model="form1"
				@submit.native.prevent>
				<el-form-item label="*店铺名称">
					<el-input placeholder="请输入你的店铺名称（不超过10个字符）" v-model="form1.name"></el-input>
				</el-form-item>
				<el-form-item label="*经营范围/业务介绍" label-width="180px">
					<el-input v-model="form1.msg" :autosize="{ minRows: 4, maxRows: 10}" type="textarea"
						placeholder="请输入你的店铺介绍（不超过50个字符）"></el-input>
				</el-form-item>
				<el-form-item label="*地区：" label-width="80px">
					<div class="code">
						<el-select v-model="form1.province" placeholder="地区">
							<el-option v-for="item in proList" :key="item.id" :label="item.province"
								:value="item.provinceid"></el-option>
						</el-select>
						<div style="margin:0 20px">省</div>
						<el-select v-if="form1.province != ''" v-model="form1.city" placeholder="地区">
							<el-option v-for="item in cityList" :key="item.id" :label="item.city" :value="item.cityid">
							</el-option>
						</el-select>
						<div v-if="form1.province != ''" style="margin:0 20px">市</div>
					</div>
				</el-form-item>
				<div class="title-t">*经营类别（可多选）</div>
				<div class="check" v-for="item in allClass" :key="item.id">
					<div class="check-head">{{item.name}}</div>
					<div class="check-box">
						<el-checkbox class="check-item" :label="val.name" @change="change(val,item,val.status)"
							v-model="val.status" v-for="val in item.littleList" :key="val.id" name="type"></el-checkbox>
					</div>
				</div>
				<div class="upload">
					<div class="lable">上传店铺咨询二维码照片(支持jpg、png格式图片)：</div>
					<el-upload :limit="1" :action="action" :file-list="fileList2" :on-success="beforeLoadW"
						list-type="picture-card" :on-remove="removeImgW">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
				<div class="upload">
					<div class="lable">上传店铺轮播图(支持jpg、png格式图片)：</div>
					<el-upload :limit="5" :action="action" :file-list="fileList3" :on-success="beforeLoadL"
						:on-progress="beforPro" list-type="picture-card" :on-remove="removeImgL">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
				<!-- 底部按钮 -->
				<div class="foot-btn">
					<!-- <div>返回上一步</div> -->
					<el-button class="f-btn" @click="next">保存</el-button>
				</div>
			</el-form>

		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			editInfoShow: Boolean,
			shop: Object,
		},
		data() {
			return {
				form1: { //修改详情资料参数
					name: '',
					province: '',
					city: '',
					headPic: '',
					msg: '',
					wxCode: '',
					shopClassify: [],
					images: '',
				},
				action: this.$util.upload,
				proList: [], //省
				cityList: [], //市
				allClass: [], //所有分类列表
				shopInfo: null,
				fileList1: [], //头像回显
				fileList2: [], //二维码回显
				fileList3: [], //轮播回显
				bigType: '',
				typeList: []

			};
		},
		watch: {
			// 监听省获取市
			'form1.province'(val) {
				if (val != '') {
					this.getCity()
				}
			}
		},
		mounted() {
			this.getPro()
			this.getClass()
			this.init()
			console.log(this.shop);
			this.form1.name = this.shop.name
			this.form1.msg = this.shop.msg
			this.proList = this.shop.province
			this.fileList1.push({
				name: '1',
				url: this.$util.host + this.shop.headPic
			})
			this.fileList2.push({
				name: '2',
				url: this.$util.host + this.shop.wxCode
			})
			this.shop.images.split(',').forEach((item, index) => {
				console.log(item);
				this.fileList3.unshift({
					name: index,
					url: this.$util.host + item,
					response: {
						data: {
							url: item
						}
					}
				})
			})
		},

		methods: {
			beforPro(val) {},
			change(val, item) {
				this.allClass.map(res => {
					res.littleList.map(res1 => {
						if (res1.status && this.bigType == '') {
							console.log(res1.status)
							this.bigType = res.name
						}
					})
				})

				if (this.bigType != '' && this.bigType != item.name) {
					this.allClass.map(res => {
						res.littleList.map(res1 => {
							if (res.name != item.name) {
								res1.status = false
							}
						})
					})
				}
				this.bigType = item.name
			},
			removeImgL(file, fileList) {
				console.log(fileList);
				let imgs = []
				imgs = fileList.map(item => {
					return item.response.data.url
				})
				this.form1.images = imgs.join(',')
			},
			beforeLoadL(response, file, fileList) {
				var imgs = []
				console.log(fileList);

				imgs = fileList.map(item => {
					return item.response.data.url
				})
				this.form1.images = imgs.join(',')
				console.log(imgs.join(','));

				// this.shop.images.split(',').forEach((item, index) => {
				//     console.log(item);
				//     this.fileList3.unshift({ name: index, url: this.$util.host + item })
				// })
			},
			getClass() {
				this.$util.post('/shop/allClassify').then(res => {

					res.data.forEach(item => {
						item.littleList.forEach(el => {
							el.status = false
						})
					})
					this.allClass = res.data
					this.allClass.forEach(e => {
						e.littleList.forEach(el => {
							this.shop.shopClassify.forEach(ite => {
								ite.littleItems.forEach(item => {
									if (el.name == item.name) {
										el.status = true
									}
								})
							})
						})
					})
				})
			},
			// 获取省
			getPro() {
				this.$util.post('/address/provinces').then(res => {
					this.proList = res.data
				})
			},
			// 回去市
			getCity() {
				this.form1.city = ''
				this.$util.post('/address/city', {
					fid: this.form1.province
				}).then(res => {
					// console.log(res.data);
					this.cityList = res.data
				})
			},
			// 上传
			beforeLoad(response, file, fileList) {
				// console.log(response);
				// console.log(file);
				console.log(fileList);
				let imgs = []
				imgs = fileList.map(item => {
					return item.response.data.url
				})
				this.form1.headPic = imgs.join(',')
			},
			removeImg(file, fileList) {
				console.log(fileList);
				let imgs = []
				imgs = fileList.map(item => {
					return item.response.data.url
				})
				this.form1.headPic = imgs.join(',')
			},
			// 上传二维码
			beforeLoadW(response, file, fileList) {
				// console.log(response);
				// console.log(file);
				console.log(fileList);
				let imgs = []
				imgs = fileList.map(item => {
					return item.response.data.url
				})
				this.form1.wxCode = imgs.join(',')
			},
			removeImgW(file, fileList) {
				console.log(fileList);
				let imgs = []
				imgs = fileList.map(item => {
					return item.response.data.url
				})
				this.form1.wxCode = imgs.join(',')
			},
			// 下一步进行接口调用
			next() {
				if (this.form1.name == '') {
					return this.$message.error('请输入你的店铺名称（不超过10个字符）');
				} else if (this.form1.msg == '') {
					return this.$message.error('请输入你的店铺介绍（不超过50个字符）');
				}
				let arr = []
				this.allClass.forEach(item => {
					let some = item.littleList.filter(el => {
						return el.status
					})

					if (some.length == 0) {
						return
					} else {
						let list = some.map(val => {
							return val.id
						})
						let obj = {
							baseId: item.id,
							littleIds: list
						}
						arr.push(obj)
						// console.log(arr);

					}
				})
				this.form1.shopClassify = arr
				if (this.form1.shopClassify.length == 0) {
					return this.$message.error('请选择分类')
				}
				console.log(this.form1.shopClassify)
				this.$util.postJ('/shop/updateShopInfo', this.form1, {
					type: true
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功')
						this.handleClose()
					} else {
						this.$message.error(res.message)
					}
				})
			},
			handleClose() {

				this.$emit('close');

			},
			init() { ///shop/myShop
				this.$util.post("/shop/myShop").then(res => {
					// res.data.forEach(ele => {
					let urls = res.data.images.split(",")
					urls.map(el => {
						// this.fileList = [{ url: this.$util.host + el }]
					})
					// res.data.images.split(",").forEach(el => {
					//     console.log(el);
					//     this.fileList = [{ url: this.$util.host + el }]
					// })

					// })
					//res.data.headPic
					this.form1 = res.data;
					console.log(res.data.images.split(","));

				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.state {
		padding: 0px 0 10px;
		border-bottom: 1px solid #eeeeee;
		display: flex;
		align-items: center;
		font-size: 22px;
		color: #333333;

		span {
			color: #e40012;
		}

		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}

	.form {
		display: flex;
		align-items: center;
		margin-top: 30px;
		margin-left: 200px;

		.lable {
			min-width: 100px;
			text-align: right;
			font-size: 16px;
			color: #999999;
			margin-right: 20px;
		}

		span {
			color: #222;
			font-size: 16px;
		}

		img {
			width: 100px;
			height: 100px;
			border-radius: 100%;
		}
	}

	.foot-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		font-size: 16px;
		cursor: pointer;
		color: #e40012;

		.f-btn {
			padding: 15px 180px;
			background: #e40012;
			color: #fff;
			font-size: 18px;
			margin-left: 40px;
		}
	}

	.avatar {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-top: 40px;
		color: #999;

		.avatar-item {
			margin-left: 25px;

			/deep/.el-upload--picture-card {
				width: 100px;
				height: 100px;
				border-radius: 100%;
				background: #fff;
				line-height: 110px;
			}

			/deep/.el-upload-list--picture-card .el-upload-list__item {
				width: 100px;
				height: 100px;
				border-radius: 100%;
			}
		}
	}

	/deep/.el-form-item__label {
		color: #999;
		font-size: 16px;
	}

	.codes {
		display: flex;
		align-items: center;

		span {
			margin: 0 10px;
		}
	}

	.title-t {
		font-size: 16px;
		color: #999999;
	}

	.check {
		margin-top: 25px;

		.check-head {
			padding: 13px 40px;
			background: #f6f6f6;
			color: #222;
			font-size: 18px;
		}

		.check-box {
			display: flex;
			align-items: center;
			// justify-content: space-between;
			flex-wrap: wrap;
			font-size: 16px;
			color: #222;

			.check-item {
				display: flex;
				align-items: center;
				margin-top: 30px;
				min-width: 16%;
			}
		}
	}

	.upload {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 30px 0;
		color: #999999;

		.lable {
			margin-right: 20px;
		}
	}

	/deep/.el-upload--picture-card {
		width: 198px;
		height: 124px;
		line-height: 130px;
		// margin-right: 90px;
	}

	/deep/.el-upload-list--picture-card .el-upload-list__item {
		width: 198px;
		height: 124px;
		margin-right: 90px;
		// margin-bottom: 50px;
	}

	.foot-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		font-size: 16px;
		cursor: pointer;
		color: #e40012;

		.f-btn {
			padding: 15px 180px;
			background: #e40012;
			color: #fff;
			font-size: 18px;
			margin-left: 40px;
		}
	}

	.publicity {
		padding: 57px 0 0;
		display: flex;
		align-items: center;
	}

	.code {
		height: 48px;
		// border: 1px solid #dcdfe6;
		display: flex;
		align-items: center;

		// width: 500px;
		input {
			height: 100%;
			// border: none;
			outline: none;
			padding: 0 15px;
			border-radius: 4px;
			font-size: 16px;
			border: 1px solid #eeeeee;
			margin-right: 10px;
		}

		button {
			background-color: transparent;
			border: none;
			color: #e40012;
			font-weight: bold;
			font-size: 14px;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input::-webkit-input-placeholder {
		color: #c0c4cc;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #c0c4cc;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #c0c4cc;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #c0c4cc;
	}
</style>
